<template>
  <c-step
    stepperGrpCd="RISK_ASSESS_STEP_CD"
    :currentStepCd="popupParam.stepCd"
    :param="param"
    v-model="stepCd"
    @stepBystep="stepBystep"
    @keySetting="keySetting"
    @research="research"
    @stepClick="stepClick"
    @closePopup="closePopup">
  </c-step>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'risk-assess-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      stepCd: '',
      searchScenario: {
        flag: '',
        isSearch: false,
      },
      planUpdateBtnData: {
        title: '계획',
        flag: false,
        research: '',
        planResearch: '',
      },
    };
  },
  watch: {
    'popupParam.stepCd'() {
      this.setStep();
    },
  },
  computed: {
    param() {
      return {
        riskAssessmentPlanId: this.popupParam.riskAssessmentPlanId,
        stepCd: this.popupParam.stepCd,
        searchScenario: {
          flag: this.searchScenario.flag,
          isSearch: this.searchScenario.isSearch,
        },
        planUpdateBtnData: this.planUpdateBtnData
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.setStep();
    },
    stepBystep(stepCd) {
      this.popupParam.stepCd = stepCd
    },
    setStep() {      
      this.stepCd = this.$_.clone(this.popupParam.stepCd);
    },
    keySetting(key) {
      this.popupParam.riskAssessmentPlanId = key
    },
    research(data) {
      if (data.place === 'scenario') {
        this.searchRiskReduce.isSearch = true;
      } else {
        this.searchScenario.isSearch = true;
      }
    },
    stepClick(step) {
      if (step === 'RAS000005' && this.searchScenario.isSearch) {
        this.searchScenario.flag = uid();
        this.searchScenario.isSearch = false;
      }
      this.planUpdateBtnData.planResearch = uid();
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
